import gql from 'graphql-tag';

export const similarProductFragment = gql`
  fragment similarProductFragment on VendorVariantModel {
    id
    title
    productUrl
    image {
      uri
    }
    images {
      id
      uri
    }
    price: currentPrice
    retailPrice {
      id
      price
    }
    salePrice {
      id
      price
    }
    vendor {
      id
      name
    }
    saleOnSalePrice {
      price
      percentage
    }
    vendorTieredDiscountCode {
      code
      discountType
      maxSavingsPercentage
      minSavingsValue
      maxSavingsValue
      lowestDiscountPrice
      highestDiscountPrice
      discountText
      discountTerms
    }
    designAward
    availability
  }
`;

export const SIMILAR_PRODUCTS_PDP = gql`
  ${similarProductFragment}
  query SimilarProductsPDP($id: ID!, $pageSize: Int!) {
    similarVendorVariants(id: $id, pageSize: $pageSize, filters: { inStock: true, commissionTiers: [2,8,3,7] }) {
      items {
        ...similarProductFragment
      }
    }
  }
`;

export const COMPLIMENTARY_PRODUCTS = gql`
  ${similarProductFragment}
  query ComplimentaryProducts($vendorVariantId: ID!) {
    complimentaryBoardProducts(vendorVariantId: $vendorVariantId) {
      id
      vendorVariant {
        ...similarProductFragment
      }
    }
  }
`;
