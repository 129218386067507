import gql from 'graphql-tag';
import { similarProductFragment } from '@graphql/VendorVariant/queries/get-similar-products.graphql';

export default gql`
  query GetSimilarProductsWithAlternates($id: ID!, $pageSize: Int!, $sourceBoardId: ID!) {
    similarVendorVariants(id: $id, pageSize: $pageSize, filters: { inStock: true, commissionTiers: [2,8,3,7] }) {
      items {
        ...similarProductFragment
      }
    }

    designerRecommendationsByVendorVariant(
      boardId: $sourceBoardId
      vendorVariantId: $id
    ) {
      ...similarProductFragment
    }
  }
  ${similarProductFragment}
`;
