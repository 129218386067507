import React from 'react';
import ResponsiveImage from '@components/shared/Image/ResponsiveImage/ResponsiveImage';
import classNames from 'classnames';
import styles from './HowHavenlyWorks.module.scss';

export default function HowHavenlyWorks() {
  return (
    <section className={styles.HowHavenlyWorks}>
      <div className={`${styles.MainContainer}`}>
        <h3 className={styles.SectionTitle}>how Havenly works</h3>
        <div className={styles.Row}>
          <div className={styles.Step}>
            <div className={styles.StepNumber}>01</div>
          </div>
          <div className={styles.Content}>
            {/* eslint-disable-next-line max-len */}
            <h4 className={styles.Title}>Match with one of our talented designers and get inspired.</h4>
            <p className={styles.Description}>
              {/* eslint-disable-next-line max-len */}
              Take a style quiz to help us get to know you a little bit better, then choose your interior designer from our personalized recommendations. With over 10 years of experience and nearly 1 million designs, our expert team will help you turn your vision into reality.
            </p>
          </div>
          <div className={classNames(styles.Media, styles.MediaGridThree)}>
            <div className={styles.Image01}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/2025/hhw_match_01.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 235,
                    height: 327
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 190,
                    height: 261
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 134,
                    height: 184
                  }
                ]}
                width={171}
                height={238}
                format={'jpeg'}
              />
            </div>
            <div className={styles.Image02}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/2025/hhw_match_02.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 238,
                    height: 158
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 188,
                    height: 125
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 132,
                    height: 89
                  }
                ]}
                width={172}
                height={114}
                format={'jpeg'}
              />
            </div>
            <div className={styles.Image03}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/2025/hhw_match_03.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 238,
                    height: 165
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 188,
                    height: 131
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 133,
                    height: 92
                  }
                ]}
                width={172}
                height={119}
                format={'jpeg'}
              />
            </div>
          </div>
        </div>

        <div className={styles.Row}>
          <div className={styles.Step}>
            <div className={styles.StepNumber}>02</div>
          </div>
          <div className={styles.Content}>
            {/* eslint-disable-next-line max-len */}
            <h4 className={styles.Title}>Partner with your designer to create a home you love.</h4>
            <p className={styles.Description}>
              {/* eslint-disable-next-line max-len */}
              Great design doesn’t happen alone. Let your designer guide you to solutions that meet your functional needs and products that suit your unique style. See it come to life with a 3D rendering of the design in your actual space.
            </p>
          </div>
          <div className={styles.Media}>
            <div className={styles.Image}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/2025/hhw_partner_01.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 476,
                    height: 327
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 376,
                    height: 258
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 269,
                    height: 183
                  }
                ]}
                width={343}
                height={226}
                format={'jpeg'}
              />
            </div>
          </div>
        </div>

        <div className={styles.Row}>
          <div className={styles.Step}>
            <div className={styles.StepNumber}>03</div>
          </div>
          <div className={styles.Content}>
            {/* eslint-disable-next-line max-len */}
            <h4 className={styles.Title}>Shop exclusive deals, and bring your design home with ease.</h4>
            <p className={styles.Description}>
              {/* eslint-disable-next-line max-len */}
              Access the best prices on your favorite home brands, get exclusive discounts, and shop everything you need for your space in a single, streamlined checkout. The result? Even better than your dreams.
            </p>
          </div>
          <div className={classNames(styles.Media, styles.MediaGridTwo)}>
            <div className={styles.Image01}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/2025/hhw_shop_01.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 236,
                    height: 326
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 186,
                    height: 257
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 131,
                    height: 182
                  }
                ]}
                width={175}
                height={232}
                format={'jpeg'}
              />
            </div>
            <div className={styles.Image02}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/2025/hhw_shop_02.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 235,
                    height: 326
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 188,
                    height: 257
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 133,
                    height: 182
                  }
                ]}
                width={174}
                height={232}
                format={'jpeg'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
